<template>
	<div class="container imgSwiper">
		<div class="swiper-container" id="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide" v-for="(it, idx) in imgList" :key="idx">
					<img class="img" :src="it.img" />
					<img class="imgHover" :src="it.imgHover" />
				</div>
			</div>
			<!-- Add Pagination -->
			<div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div>
		</div>
	</div>
</template>
<script>
import Swiper from "swiper";
export default {
	name: "imgList",
	props: {
		imgList: {
			type: Array,
			default: [],
		},
	},
	components: { Swiper },
	data() {
		return {
			mySwiper: null,
		};
	},
	created() {},
	mounted() {
		this.initPage();
	},
	methods: {
		initPage() {
			this.$nextTick(() => {
        let width = window.innerWidth
				this.mySwiper = new Swiper("#swiper-container", {
					slidesPerView:width > 1440?8:6,
					// spaceBetween:0,
					centeredSlides: true,
					loop: true,
					// pagination: {
					//   el: ".swiper-pagination",
					//   clickable: true
					// },
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				});
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.imgSwiper .swiper-container {
	width: 100%;
	height: 100%;
}
.imgSwiper .swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	transition: 300ms;
	transform: scale(0.9);
}
.imgSwiper .swiper-slide-active,
.swiper-slide-duplicate-active {
	transform: scale(0.9);
  z-index:1000;
}
.imgHover {
	display: none;
}

.imgSwiper .swiper-slide {
	.img {
		display: block;
	}
	.imgHover {
		display: none;
	}
}
.imgSwiper .swiper-slide-active {
	.img {
		display: none;
	}
	.imgHover {
		display: block;
		// margin-left:-15px;
		// z-index:1000;
	}
}
.imgSwiper .swiper-button-prev,.imgSwiper .swiper-button-next {
  width:50px;
  height:364px;
	  // background-image:url('../../static/images/next.png');
  // background-color:rgba(96,202,181,0.5);
  background-color:#ff6817;
  opacity: 0.36;
  background-repeat: no-repeat;
  background-position: center;
  top:74px;
}
.imgSwiper .swiper-button-prev{
  // background-image:url('../../static/images/prev.png');
  left:0;
	text-indent: -9999px;
}
.imgSwiper .swiper-button-next {
  // background-image:url('../../static/images/next.png');
  right:0;
	text-indent: -9999px;
}
.imgSwiper .swiper-button-next {
}
.imgSwiper .swiper-button-prev:after,.imgSwiper .swiper-button-next:after{
  color:#fff;
  font-size: 30px;
	font-family:none;
}
/deep/ .swiper-wrapper{
  align-items: center;
}
</style>
