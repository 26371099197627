
export const pList = [
  {"img":require("@img/p-icon1.png"),"name":"企蜂名片","tolink":"/product/card","alt":"电子名片"},
  {"img":require("@img/p-icon2.png"),"name":"表单营销","tolink":"/product/formMarket","alt":"表单获客"},
  // {"img":require("@img/p-icon3.png"),"name":"热点推广","tolink":"/product/hot"},
  {"img":require("@img/p-icon7.png"),"name":"慧获客","tolink":"/product/huiVisitors","alt":"获客系统"},
  // {"img":require("@img/p-icon5.png"),"name":"微销","tolink":"/product/wxMarket"},
  // {"img":require("@img/p-icon6.png"),"name":"场景秀","tolink":"/product/show"},
  // {"img":require("@img/p-icon9.png"),"name":"微客服","tolink":"/product/customer"},
  // {"img":require("@img/p-icon10.png"),"name":"海报裂变","tolink":"/product/poster"},
  // {"img":require("@img/p-icon8.png"),"name":"融E信","tolink":"/product/letterE"},
  {"img":require("@img/p-icon14.png"),"name":"呼叫中心","tolink":"/product/callCenter","alt":"呼叫中心系统"},

  // {"img":require("@img/p-icon12.png"),"name":"小蜂助手","tolink":"/product/xiaofeng"},
  {"img":require("@img/p-icon4.png"),"name":"电销卡","tolink":"/product/sale","alt":"电销卡"},
  {"img":require("@img/p-icon13.png"),"name":"微信营销","tolink":"/product/wechat","alt":"SCRM系统"},
  {"img":require("@img/p-icon15.png"),"name":"外呼系统","tolink":"/product/robot","alt":"电销系统"},
  // {"img":require("@img/p-icon14.png"),"name":"网络电话","tolink":"/product/onlineCall"},
  {"img":require("@img/p-icon8.png"),"name":"企蜂传媒","tolink":"/product/qfcm","alt":"软文推广"},
  {"img":require("@img/p-icon18.png"),"name":"电销线路","tolink":"/product/dx","alt":"电销线路"},
  {"img":require("@img/p-icon16.png"),"name":"小F机器人","tolink":"/product/dxjqr","alt":"小F机器人"},
  {"img":require("@img/p-icon17.png"),"name":"AI数智","tolink":"/product/aisz","alt":"AI数智"},

]

export const caseData = [
  {"name":"财税行业","tolink":"/case/cs"},
  {"name":"传媒行业","tolink":"/case/cm"},
  {"name":"电子商务行业","tolink":"/case/dzsw"},
  {"name":"房地产行业","tolink":"/case/fdc"},
  {"name":"互联网行业","tolink":"/case/hlw"},
  {"name":"教育行业","tolink":"/case/jy"},
  {"name":"金融服务行业","tolink":"/case/jrfw"},
  {"name":"汽车行业","tolink":"/case/qc"},
  {"name":"制造行业","tolink":"/case/zz"},
  {"name":"装修行业","tolink":"/case/zx"},
]

export const bpList = [
  {"name":"CRM管理系统","tolink":"/crm"},
  {"name":"电销系统","tolink":"/outbound"},
  {"name":"电销机器人","tolink":"/product/dxjqr"},
  {"name":"企蜂名片","tolink":"/product/card"},
  // {"name":"小蜂助手","tolink":"/product/xiaofeng"},
  // {"name":"热点推广","tolink":"/product/hot"},
  // {"name":"场景秀","tolink":"/product/show"},
  // {"name":"海报裂变","tolink":"/product/poster"},
  // {"name":"融E信","tolink":"/product/letterE"},
  // {"name":"微客服","tolink":"/product/customer"},  
  // {"name":"微销","tolink":"/product/wxMarket"},
  {"name":"找企鸟","tolink":"/zqn"},
]

export const menu = [
  // {"name":"企蜂传媒","tolink":"/qfcm/",'url':'/qfcm',"className":"","current":'noCurrrent'},
  // {"name":"CRM2","tolink":"/cr2/",'url':'/cr2',"className":"","current":'noCurrrent'},
  {"name":"CRM","tolink":"/crm/",'url':'/crm',"className":"","current":'noCurrrent'},
  {"name":"电销系统","tolink":"/outbound/","url":"/outbound","className":"","current":'noCurrrent'},
  // {"name":"慧沟通","tolink":"/hgt",'url':'/hgt',"className":"","current":'noCurrrent'},
  {"name":"慧营家","tolink":"/hyj",'url':'/hyj',"className":"","current":'noCurrrent'},
  {"name":"产品","tolink":"",'url':'/product','tolink':'#',"className":"open","current":'noCurrrent'},
  {"name":"找企鸟","tolink":"/zqn","url":"/zqn","className":"","current":'noCurrrent'},
  {"name":"行业案例","tolink":"",'url':'/case','tolink':'#',"className":"opencase","current":'noCurrrent'},
  // {"name":"软件下载","tolink":"/softDown/","url":"/softDown","className":"","current":'noCurrrent'},
  // {"name":"招商代理","tolink":"/busAgent/","url":"/busAgent","className":"","current":'noCurrrent'},
  {"name":"新闻动态","tolink":"/newsList.do","url":"/newsList.do","className":"","current":'noCurrrent'},
  {"name":"开通试用","tolink":"#","url":"#","className":"ptryUse"},
  {"name":"关于我们","tolink":"/about/","url":"/about","className":"","current":'noCurrrent'},
  // {"name":"企蜂云官网","tolink":"http://www.qifengyun.com/","url":"http://www.qifengyun.com/","className":"","current":'noCurrrent',"target":"_blank"}
]

export const soft = [
  {"name":"慧营销领航版","toDown":""},
  {"name":"慧营销专业版","toDown":""},
  {"name":"慧营销标准版","toDown":""},
  {"name":"杭州电信商话","toDown":""},
  {"name":"慧拨","toDown":""}
]

export const about = [
  {"name":"公司介绍","toLink":"/about/"},
  {"name":"联系我们","toLink":"/about?id=conact"},
  {"name":"帮助中心","toLink":"http://help.qftx.net:9941/#/"},
  // {"name":"招商代理","toLink":"/busAgent/"},
]

export const conact = [
  {"name":"4008262277(售后服务)","icon":'iconfont icon-qq'},
  {"name":"18958188698","icon":'iconfont icon-shouji'},
  {"name":"zhangxiaolin@qifengyun.com","icon":'iconfont icon-icon1'},
  {"name":"浙江省杭州市西湖区翠苑街道古翠路80号浙江科技产业大厦12楼#","icon":'iconfont icon-dingwei'}
]

export const products = [
  {"name":"慧营销","tolink":"http://qftx.com/",'url':'/'},
  // {"name":"遇仁招聘","tolink":"http://www.yurenwang.net",'url':'/crm'},
  // {"name":"企蜂商城","tolink":"http://www.qifengyun.com/products/qfsc",'url':'http://www.qifengyun.com/products/qfsc'},
  {"name":"慧营家","tolink":"http://www.huiyingjia.com/",'url':'/hyj'},
  // {"name":"绘新直播","tolink":"http://www.qifengyun.com/products/hxzb",'url':'http://www.qifengyun.com/products/hxzb'},
  {"name":"找企鸟","tolink":"http://www.zhaoqiniao.cn/",'url':'/zqn'},
  // {"name":"Signwei","tolink":"http://www.signwei.com",'url':'/crm'}
]

export const footer = {
  products: [{
      index: "2-1",
      name: "CRM管理系统",
      path: "/crm/",
      icon: ""
    },
    {
      index: "2-2",
      name: "慧营家",
      path: "/hyj",
      icon: ""
    },
    {
      index: "2-3",
      name: "AI数智",
      path: "/",
      icon: ""
    },
    {
      index: "2-4",                          
      name: "企蜂名片",
      path: "/product/card/",
      icon: ""
    },
    {
      index: "2-5",
      name: "找企鸟",
      path: "/zqn",
      icon: ""
    },
    // {
    //   index: "2-6",
    //   name: "小F机器人",
    //   path: "/products/dxjqr",
    //   icon: ""
    // }
  ],
  about: [{
      index: "a-1",
      name: "公司介绍",
      path: "/about",
      icon: ""
    },
    {
      index: "a-2",
      name: "联系我们",
      path: "/about?id=conact",
      icon: ""
    },
    {
      index: "a-3",
      name: "帮助中心",
      path: "http://help.qftx.net:9941/#/",
      icon: ""
    }
  ],
  contacts: [{
      key: "mobile",
      icon: "iconfont icon-extra-mobile",
      text:'售前电话',
      value: "18958188698"
    },
    {
      key: "400-826-2277",
      icon: "iconfont icon-extra-tel",
      text:'售后电话',
      value: "400-826-2277"
    },
    {
      key: "18958188698",
      icon: "iconfont icon-extra-tel",
      text:'商务合作',
      value: "18958188698"
    },
    {
      key: "email",
      icon: "iconfont icon-extra-email",
      text:'邮箱',
      value: "zhangxiaolin@qifengyun.com"
    },
    // {
    //   key: "qq",
    //   icon: "iconfont icon-extra-qq",
    //   value: "4008262277"
    // },
    // {
    //   key: "location",
    //   icon: "iconfont icon-extra-location",
    //   value: "浙江省杭州市西湖区翠苑街道古翠路80号浙江科技产业大厦12楼"
    // }
  ],
  qrcode: [
    {
      key: "售前客服",
      title: "售前客服",
      // imgUrl:require('@images/codeFooter.jpg'),
      tips: "关注我们，关注企蜂云"
    },
    {
      key: "微信公众号",
      title: "微信公众号",
      // imgUrl:require('@images/codeFooter2.jpg'),
      tips: "扫描二维码关注微信号"
    }
  ],
  links: [
    {
      name: "百度",
      url: "http://www.baidu.com"
    },
    {
      name: "企蜂通讯",
      url: "http://www.qftx.com/"
    }
  ],
  copyright: [{
      key: "company",
      value: "浙江企蜂信息技术有限公司"
    }, {
      key: "copyright",
      value: "copyright@2009-",
    }, {
      key: "record_num",
      value: "浙ICP备19031541号",
      url: "https://beian.miit.gov.cn"
    },
    //  {
    //   key: "seo_baidu",
    //   value: "百度地图",
    //   url: "/sitemap.html"
    // }, {
    //   key: "seo_google",
    //   value: "谷歌地图",
    //   url: "/sitemap.xml"
    // },
    // {
    //   key: "bizcn",
    //   value: "工信部网站",
    //   url: "https://beian.miit.gov.cn"
    // }
  ]
}