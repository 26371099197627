<template>
  <div class="homePage">
    <!-- <Banner :banner="banner" :btn="false"></Banner> -->
    <div v-cloak style="height:578px;overflow: hidden;"> 
      <!-- :after-change="onChange" autoplay -->
      <carousel arrows autoplay :autoplay-speed="5000"  v-show="banners.length > 0">
        <CarouselItem  v-for="(item, index) in banners" :key="index"  class="bannerSwiper">
          <div
            :style="{ 'background-image': 'url(' + item.url + ')' }"
            class="bannerSwiper"
          >
          <div v-if="index==0" class="AppContent flex flex-between">
            <div>
              <apply-dialog buttonColor='slot'>
                <template slot="btn">
                  <button class="goToDetails mt420" >免费咨询</button>
                </template>
              </apply-dialog> 
            </div>            
            <div class="animationStyle">
              <img class="img-slide-fade1 incon-it1" src="../../assets/images/hgt/icon1.png" alt="">
              <img class="img-slide-fade2 incon-it2" src="../../assets/images/hgt/icon2.png" alt="">
              <img class="img-slide-fade3 incon-it3" src="../../assets/images/hgt/icon3.png" alt="">
              <img class="img-slide-fade4 incon-it4" src="../../assets/images/hgt/icon4.png" alt="">
            </div>
          </div> 
          <div v-if="index==1" class="AppContent flex flex-between">
            <apply-dialog buttonColor='slot'  style="margin:350px auto;display: block;" >
              <template slot="btn">
                <button class="goToDetails" >免费咨询</button>
              </template>
            </apply-dialog> 
          </div>
          <div v-if="index==2" class="AppContent flex flex-between">
            <div>
              <apply-dialog buttonColor='slot'  style="margin-top:350px;" >
                <template slot="btn">
                  <button class="goToDetails mt392" >免费咨询</button>
                </template>
              </apply-dialog>             
            </div>
            <div class="animationStyle">
              <img class="pulsing-icon incon-b1" v-if="index==2" src="../../assets/images/hgt/banner-3-1.png" alt="">
              <img class="pulsing-icon incon-b2" src="../../assets/images/hgt/banner-3-2.png" alt="">
              <img class="pulsing-icon incon-b3" src="../../assets/images/hgt/banner-3-3.png" alt="">
              <img class="pulsing-icon incon-b4" src="../../assets/images/hgt/banner-3-4.png" alt="">
            </div>
          </div>
          <div v-if="index==3" class="AppContent">
            <apply-dialog buttonColor='slot' >
              <template slot="btn">
                <button class="goToDetails"  style="margin:430px 0;background:#fd5853;border-radius: 0;">免费咨询</button>
              </template>
            </apply-dialog> 
          </div>
        </div>
        </CarouselItem>
      </carousel>
    </div>
    <TitleAssembly :titleObj="indexMd1.title" />
    <div class="homeIndexMode1 AppContent">  
      <ul class="flex flex-between">
        <li v-for="(item,index) in indexMd1.list" :key="index" >
          <h3>
            <img :src="item.icon" alt="" />
            {{item.title}}
          </h3>
          <div class="homeIndexMode1-item">
            <p v-for = '(it,idx) in item.p' :key='idx'>
              {{it}}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <TitleAssembly :titleObj="indexMd2.title" />
    <div class="homeIndexMode2 AppContent" >  
      <ul class="flex flex-between">
        <li v-for="(item,index) in indexMd2.list"  @click="handleToDetails(index)" :key="index"  :style="{'background-image':'url('+item.img+')'}">
          <h3>
            {{item.text}}
          </h3>
          <!-- <apply-dialog buttonColor='slot' >
            <template slot="btn">
              <button class="goToDetails white">免费咨询</button>
            </template>
          </apply-dialog>  -->
        </li>
      </ul>
    </div>
    <TitleAssembly :titleObj="indexMd3.title" />
    <div class="homeIndexMode3 AppContent flex flex-between">  
       <ul class="IndexTabLeft">
         <li :class="{'active':activeArr[index]}" v-for="(item,index) in indexMd3.tabLeft" :key="index" @click="changeTab(index)">{{item}}</li>
       </ul>
       <div class="IndexTabCenter ">
        <img :src="indexMd3.tableRight[tabIndex].img" alt="">
      </div>
      <div class="IndexTabRight ">
        <h3>{{indexMd3.tableRight[tabIndex].title}}</h3>
        <ul>
          <li v-for="(item,index) in indexMd3.tableRight[tabIndex].p"  :key="index" >
          {{item}}
          </li>
        </ul>
      </div>
    </div>
    <div class="homeImgSwiper">
			<TitleAssembly :titleObj="indexMd4.title"></TitleAssembly>
      <div class="imgSwiper">
				<ImgSwiper
					:imgList="indexMd4.swiperList"
					v-if="indexMd4.swiperList.length > 0"
				/>
			</div>
		</div>
    <TitleAssembly :titleObj="indexMd5.title" />
    <div class="oneImg">  
      <img src="../../assets/images/hgt/md6.png" alt="">
    </div>
    <apply-dialog buttonColor='slot'>
      <template slot="btn">
        <button class="goToDetails colorwh" >免费咨询</button>
      </template>
    </apply-dialog>
    <div class="homeIndexMode4">  
      <apply-dialog buttonColor='slot'  style="display: block;" >
        <template slot="btn">
          <button class="goToDetails" >免费咨询</button>
        </template>
      </apply-dialog> 
    </div>
  </div>
</template>
<script>
import '../../assets/css/swiper.css'
import TitleAssembly from "./components/TitleAssembly";
import { Carousel,Button,CarouselItem} from 'iview'
// import ApplyButton from "@/components/applyButton";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import ImgSwiper from "./components/ImgSwiper";
// import { index,productBanners } from "@/static/js/htmlContent";
import { hgt } from "@public/static/json/htmlContent";

export default {
  name: "home",
  components: {
    // ApplyButton,
    ApplyDialog,
    // Banner,
    TitleAssembly,
    // Footer,
    ImgSwiper,
    Button,
    Carousel,
    CarouselItem
  },
  data() {
    return {
      banners:[],
      // banner: hgt.banner,
      indexMd1: hgt.md1,
      indexMd2: hgt.md2,
      indexMd3: hgt.md3,
      indexMd4: hgt.md4,
      indexMd5 :hgt.md5,
      tabIndex:0,
      activeArr:new Array(5).fill(false)
    };
  },
  async asyncData({ route }) {
		// console.log(route);
		// let host = process.env.baseUrl;
		// const asyncData = {
		// 	links: [],
		// };
		// await new Promise((resolve, reject) => {
		// 	axios
		// 		.get(`https://wzif.qifengyun.net:8004/qf/link/getLinks?websiteCode=HGT`)
		// 		.then((res) => {
		// 			console.log(res.data, "友情链接");
		// 			asyncData.links = res.data.data.linkEnts;
		// 			resolve(res.data.data);
		// 		})
		// 		.catch((error) => {
		// 			reject(error);
		// 		});
		// });
		// return asyncData;
	},
  created() {
    this.activeArr[this.tabIndex] = true
    // this.$nextTick(()=>{
      this.banners = hgt.bannerSwiper
    // })
  },
  mounted() {},
  methods: {
    changeTab(index){
      this.tabIndex = index 
      this.activeArr = new Array(5).fill(false)
      this.activeArr[index] = true
    },
    // handleLink(){
    //   // window.location.href = '/aiIntent'
    //   // this.$router.push('/aiIntent')
    //   window.open(this.$router.resolve({path:'/aiIntent'}).href,'_blank')
    // },
    handleToDetails(index){
      let path = ''
      switch (index){
        case 0:
          path = '/commSolutions'   // 通信解决方案
          break;
        case 1:
          path = '/getCustomers'   // 引流获客
          break;
        case 2:
          path = '/aiIntent'   // AI意向
          break;
        case 3:
          path = '/crmSolutions'   // 慧沟通CRM解决方案
          break;     
       default:
        break; 
      }  
      window.open(this.$router.resolve({path}).href,'_blank')
    }
  },
};
</script>
<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.homeIndexMode1{
  li{
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    cursor: pointer;
    width: 390px;
    overflow: hidden;
    h3{
      width:100%;
      height:93px;
      line-height:93px;
      padding-left:35px;
      background: url(../../assets/images/hgt/3-image3-CRM-bg.png) no-repeat;
      margin-bottom:15px;
      font-size: 20px;
      color:#2D3254;
      font-weight: bold;
    }
    p{
      padding-left:20px;
      background: url(../../assets/images/hgt/2-icon4.png) no-repeat left 5px;
      margin-bottom:15px;
      font-size:14px;
      color:#333;
    }
    .homeIndexMode1-item{
      padding:0 30px;
    }
    .under-details{
      padding-left:50px;
      color:#FF6817;
      margin-bottom:15px;
      cursor: pointer;
    }
  }
  li:hover{
    box-shadow: -6px 0px 15px 6px rgba(255,104,23,0.12); 
    border: 1px solid #ff6817;
  }
}
.homeIndexMode2{
  cursor: pointer;
  li{
    width: 290px;
    height: 393px;
    background: #080e26;
    border-radius: 6px;
    h3{
      font-size: 20px;
      font-weight: bold;
      color:#fff;
      text-align: center;
      padding-top:183px;
    }
  }
  li:hover{
    // box-shadow: 0px 0px 15px 6px #f5f5f5; 
    transform: scale(1.1);
    // border: 1px solid #f5f5f5;
  }
}
.homeIndexMode3{
  height: 416px;
  background: #f4f7ff;
  border-radius: 8px;
  padding:10px;
  align-items: center;
  .IndexTabLeft{
    cursor: pointer;
    margin-left:46px;
    li{
      width: 160px;
      height: 52px;
      border-radius: 8px;
      margin-bottom:16px;
      text-align: center;
      line-height: 52px;
      color: #2d3254;
      font-size: 16px;
      border: 1px solid #dedede;
      background:#fff;
    }
    li:hover,li.active{
      color:#fff;
      background: #FF6817;
      border: 1px solid #FF6817;
    }
  }
  .IndexTabCenter{

  }
  .IndexTabRight{
    width:180px;
    h3{
      font-size: 24px; 
      font-weight: bold;
      color: #333333;
    }
    li{
      padding-left:25px;
      color: #333333;
      line-height: 36px;
      font-size: 16px;
      background:url(../../assets/images/hgt/md3icon.png) no-repeat left center;
    }
  }
}
.goToDetails{
  display: block;
  width: 140px;
  margin:30px auto;
  height: 42px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 42px;
  cursor: pointer;
  background: #FF6817;
  border:1px solid #FF6817;
  color:#fff;
}

.white{
  color:#333;
  background: #ffffff;
  border-color:#fff;
} 
.homeIndexMode4{
  background:url(../../assets/images/hgt/5image.png) no-repeat center;
  height:254px;
  padding-top:1px;
  margin-bottom:62px;
  .goToDetails{
    margin-top:150px;
  }
}
.homeImgSwiper {
	margin-bottom: 70px;
	.imgSwiper {
		margin-top:-20px;
	}
	.imgSwiper .swiper-button-prev,
	.imgSwiper .swiper-button-next {
		background-color: rgba(50, 93, 205, 0.5);
	}
}
.bannerSwiper {
	height: 578px;
	background-position: center;
	background-repeat: no-repeat;
  overflow: hidden;
}
.animationStyle{
  position: relative;
  // background:red;
  height:400px;
  width:600px;
  img{
    position:absolute;
  }
  .incon-it1{
    left:74px;
    top:73px;
  }
  .incon-it2{
    right:0px;
    top:223px;
  }
  .incon-it3{
    left:20px;
    bottom:45px;
  }
  .incon-it4{
    left:288px;
    top:25px;
  }
  .incon-b1{
    right:74px;
    top:21px;
  }
  .incon-b2{
    left:190px;
    top:53px;
  }
  .incon-b3{
    left:74px;
    bottom:49px;
  }
  .incon-b4{
    left:175px;
    bottom:-20px;
  }
}
.img-slide-fade1 {
  opacity: 0;
  transform: translateY(30px); /* 从下方 30px 开始 */
  animation: slideFade 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: 0.5s; /* 延迟启动 */
}
.img-slide-fade2 {
  opacity: 0;
  transform: translateY(30px); /* 从下方 30px 开始 */
  animation: slideFade 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: 0.5s; /* 延迟启动 */
}

.img-slide-fade3 {
  opacity: 0;
  transform: translateY(30px); /* 从下方 30px 开始 */
  animation: slideFade 2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: 0.5s; /* 延迟启动 */
}
.img-slide-fade4 {
  opacity: 0;
  transform: translateY(30px); /* 从下方 30px 开始 */
  animation: slideFade 3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  animation-delay: 0.5s; /* 延迟启动 */
}

@keyframes slideFade {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/deep/ .ant-carousel {
  .slick-dots li.slick-active button{
    background:#FF6817!important;
  }
 .slick-dots li button{
    height:6px!important;
  }
}

.ant-carousel .slick-dots li.slick-active button
.slick-active{
  background:red;
}
/* 修复示例：确保仅隐藏非激活项 */
/deep/ .ant-carousel{
  // .slick-slide:not(.slick-current) {
  //   display: none !important;
  // }
} 

.pulsing-icon {
  // background: url(你的图标路径) no-repeat center; /* 替换你的图标 */
  // background-size: contain;
  animation: pulse 2s ease-in-out infinite; /* 动画名称 | 时长 | 缓动效果 | 无限循环 */
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1); /* 原始大小 */
  }
  50% {
    transform: scale(1.2); /* 放大到1.2倍 */
  }
}
.mt420{
  margin-top:420px;
}
.mt392{
  margin-top:392px;
}
.mr30{
  margin-left:30px;
}

.applyBtn {
    width:200px;
    height: 51px;
    font-size: 24px;
    border: 1px solid #5348f7;
    background: none;
    color: #5348f7;
    cursor: pointer;
    cursor: pointer;
    display: block;
    text-align: center;
    line-height: 51px;
    border-radius: 8px;
    margin-bottom:100px;
  }
  .applyBtn:hover {
    background: #5348f7;
    color: #fff;
  }
</style>

<!-- <style>
.ant-carousel .slick-slide {

display: none!important;

}

.ant-carousel .slick-slide.slick-active {

display: block!important;

}
</style> -->


