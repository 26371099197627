<!--  -->
<template>
<div>
  <Nav></Nav>
<!--   
电脑屏幕：<Icon type="md-desktop" />
苹果：<Icon type="logo-apple" />
安卓：<Icon type="logo-android" /> -->
  <div class='wrap'>
    <h5><span>畅享智能营销新工具</span></h5>
    <div class="linghang">
        <div  v-for="(item,index) in downList1" :key="index" >
          <div :class="'first'+index" class="iconDown-a">
            <Icon v-if="item.prodName == '慧营销领航版'" type="md-desktop" size="70" color="#fff" />
            <Icon v-if="item.prodName == '慧营销iOS版'" type="logo-apple" size="90" color="#fff" />
            <Icon v-if="item.prodName == '慧营销Android版'" type="logo-android" size="90" color="#fff"/>
          </div>
          <div class="downText">
            <h6>{{item.prodName}}</h6>
            <p>版本号：{{item.fileVersion}}</p>
            <p>更新时间：{{item.inputTime}}</p>
            <div v-if="item.button"><a class="down-btn" :href="item.fileUrl">立即下载</a></div>
            <div v-else><img :src="item.fileUrl" alt=""><p>扫码下载</p></div>
          </div>
        </div>
    </div>
    <h5><span>更多历史版本</span></h5>
    <!-- <div v-for="(item,index) in downList2" :key="index"> -->
      <Module :title="downList2.groupType" :listText="downList2"></Module> 
    <!-- </div> -->
      
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import Api from '@api/down'
import Module from "./components/softDownModule"
import { Icon } from 'iview'
export default {
  name:'',
  components: {
    Module,
    Icon
  },
  props:{},
  data() {
     return {
        listText1:[],
        listText2:[],
        listText3:[],
        downList1:[],
        downList2:[]
     };
  },
  computed: {},
  watch: {},
  created() {
      this.getSoft()
  },
  mounted() {

  },
  //方法集合
  methods: {
      getSoft(){
        Api.fetchSoftDown().then((res)=>{
            const result = res.data.data;
            let list = res.data.data.list
            list.map((item,index)=>{
              if(item.groupType == '慧营销PC'){
                this.downList1 = item.subList
              }else if(item.groupType == '行业版本'){
                this.downList2 = item.subList
              }
            })
          
        })
      }
  },
}
</script>
<style lang='scss' scoped>
  //@import url(); 引入公共css类
  .wrap{
    h5{
      margin-top:30px;
      text-align: center;
      span{
        font-size:30px;color:#333;border:4px solid #44b5f2;border-top:none;border-bottom:0;line-height:30px;margin:40px 0;text-align: center;display:inline-block;
      padding:0 20px; 
      }
    }
  }
  .linghang{
    margin-bottom:50px;
    display: flex;
    justify-content: space-around;
    div{
      width:301px;
      div.iconDown-a{
        width:301px;
        height:199px;
        display:flex;
        justify-content: center;
        align-items: center;
      }
      .first0{
        background:url('../../assets/img/softBg-img1.png') no-repeat ;
      }
      .first1{
        background:url('../../assets/img/softBg-img2.png') no-repeat ;
      }
      .first2{
        background:url('../../assets/img/softBg-img3.png') no-repeat ;
      }
    }
    .downText{
      text-align: center;
      line-height:30px;
      h6{font-size:20px;color:#777777;margin:30px 0 10px 0;}
      .down-btn{
        display: block;
        margin:25px auto 0;
        width:215px;
        height:60px;
        background:#fc9d2c;
        border-radius:8px;
        color:#fff;
        font-size:20px;
        line-height:60px;
        text-align: center;
      }
      p{color:#808080;}
      img{
        display:block;
        margin:20px auto 0;
        width:70px;
        height:70px;

       
      }
    }
  }
</style>