import { render, staticRenderFns } from "./AIsz.vue?vue&type=template&id=e885a828&scoped=true&"
import script from "./AIsz.vue?vue&type=script&lang=js&"
export * from "./AIsz.vue?vue&type=script&lang=js&"
import style0 from "./AIsz.vue?vue&type=style&index=0&id=e885a828&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e885a828",
  null
  
)

export default component.exports