<!--  -->
<template>
  <div class="box">
    <Banner :bgImg="bannerImg" bgcolor="#1cacfa">
      <div class="banner-text">
        <h4>慧营销CRM成功助力</h4>
        <ul>
          <li class="tit1">100000+中小企业数字化转型，覆盖“销售+管理+服务”全流程</li>
          <li>多渠道低成本拓客、智能外呼、录音、高效客户转化、全流程销售漏斗、</li>
          <li>可视化数据报表、云电销、智能语音系统、智能名片</li>
        </ul>
      </div>
      <apply-dialog></apply-dialog>
    </Banner>

    <div class="sec1">
      <Title :title="crmTitle5" :moduleText="crmText5" :titleStyle="false" lineW="1000px"></Title>
      <ul>
        <li v-for="(item,index) in whyList" :key="index">
          <img :src="item.img" alt />
          <h2>{{item.title}}</h2>
          <p>{{item.text}}</p>
        </li>
      </ul>
    </div>

    <div class="sec2">
      <Module
        :salesSerText="sec2List1"
        :topicIMg="topicIMg1"
        :imgTitle="imgJsonOutbound.img1.title"
        :imgAlt="imgJsonOutbound.img1.alt"
        :type="false"
      ></Module>
      <Module
        :salesSerText="sec2List2"
        flexDrect="row-reverse"
        :topicIMg="topicIMg2"
        :imgTitle="imgJsonOutbound.img2.title"
        :imgAlt="imgJsonOutbound.img2.alt"
        :type="false"
      ></Module>
      <Module
        :salesSerText="sec2List3"
        :topicIMg="topicIMg3"
        :imgTitle="imgJsonOutbound.img3.title"
        :imgAlt="imgJsonOutbound.img3.alt"
        :type="false"
      ></Module>
      <Module
        :salesSerText="sec2List4"
        flexDrect="row-reverse"
        :topicIMg="topicIMg4"
        :imgTitle="imgJsonOutbound.img4.title"
        :imgAlt="imgJsonOutbound.img4.alt"
        :type="false"
      ></Module>
    </div>

    <div class="sec3">
      <tb-module
        :moduleImg="moduleImg3"
        :moduleText="moduleText3"
        title="100000+企业的选择---慧营销CRM不辜负每个客户的信赖"
        lineW="1200px"
        :titleStyle="false"
        :imgTitle="saleImg.img6.title"
        :imgAlt="saleImg.img6.alt"
      ></tb-module>
      <!-- <a href>查看更多>></a> -->
    </div>

    <div class="sec4">
      <img src="./../../assets/img/crm2back.png" alt />
      <img @click = "$store.commit('CHANGE_MODAL_TRUE')" class="btn" src="./../../assets/img/crm2button.png" alt />
      <!-- <button class="applayBtnRed" @click = "$store.commit('CHANGE_MODAL_TRUE')">开通申请</button> -->
    </div>
    <apply-dialog-btn :modelVisible="$store.state.applyModal" @cancle = "$store.commit('CHANGE_MODAL_FALSE')"></apply-dialog-btn>
  </div>
</template>
<script>
import Vue from "vue";
import Banner from "@/modudel/Banner.vue";
import Title from "@/modudel/Title.vue";
import Module from "./../outbound/components/module";
import ApplyDialog from "@/modudel/ApplyDialog.vue";
import TbModule from "@/modudel/TbModule.vue";
import {
  prodAdvantage,
  sec2List1,
  sec2List2,
  sec2List3,
  sec2List4,
  whyList,
} from "./json";
export default {
  data() {
    return {
      bannerImg: require("../../assets/img/crm2banner.png"),
      topicIMg1: require("../../assets/img/crm2_1.png"),
      topicIMg2: require("../../assets/img/crm2_2.png"),
      topicIMg3: require("../../assets/img/crm2_3.png"),
      topicIMg4: require("../../assets/img/crm2_4.png"),
      prodAdvantList: [],
      sec2List1: [],
      sec2List2: [],
      sec2List3: [],
      sec2List4: [],
      whyList: [],
      imgJsonOutbound: this.$store.state.imgJson.crm,
      moduleImg3: require("../../assets/img/home-module8.png"),
      moduleText3:
        "覆盖互联网、金融教育、电商、汽车行业、企业服务、医疗美容、房地产等众多行业领域，产品应用普及率广泛",
      saleImg: this.$store.state.imgJson.sale,

      crmTitle5: "为什么选择慧营销CRM?",
      crmText5:
        "一站式解决中小企业拓客难，开发效率低，签单转化率低，销售管理杂乱的营销难题<br/>全面提升中小企业核心竞争力",
    };
  },
  components: {
    Banner,
    Module,
    Title,
    ApplyDialog,
    TbModule,
  },
  methods: {},
  computed: {},
  created() {
    this.prodAdvantList = prodAdvantage;
    this.sec2List1 = sec2List1;
    this.sec2List2 = sec2List2;
    this.sec2List3 = sec2List3;
    this.sec2List4 = sec2List4;
    this.whyList = whyList;
  },
  mounted() {},
};
</script>

<style lang='scss' scoped>
.banner-text {
  padding-top: 120px;
  color: #fff;
  h4 {
    font-size: 40px;

    line-height: 80px;
  }
  li {
    // height:50px;
    font-size: 18px;
    line-height: 36px;
  }
  li.tit1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 40px;
  }
}
.sec1 {
  width: 1200px;
  margin: 0 auto;
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 23%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
      }
      h2 {
        font-size: 30px;
        color: #333333;
        margin: 20px;
      }
      p {
        font-size: 14px;
        text-align: center;
        color: #808080;
        width: 100%;
      }
    }
  }
}
.sec2 {
  width: 1200px;
  margin: 120px auto 0;
}
.sec3{
  margin-top: -80px;
  a{
    display: block;
    text-align: center;
    margin: 30px auto;
    color: #4868c8;
  }
}
.sec4 {
  position: relative;
  margin-bottom: -60px;
  margin-top: 50px;
  img {
    width: 100%;
  }
  img.btn {
    width: 215px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-107px);
  }
}
</style>
