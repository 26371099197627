<!--  -->
<template>
  <div class='' >
    <button v-if="buttonColor==='white'" class="white-button"  @click="formDialog()">{{btnText}}</button>
    <button v-if="buttonColor==='purLine'" class="pur-button" style="border:1px solid #5348F7;background:none"  @click="formDialog()">{{btnText}}</button>
    <button v-else-if="buttonColor === 'blue'" class="blue-button"  @click="formDialog()">{{btnText}}</button>
    <button v-else-if="buttonColor === 'blueline'" class="applayBtnBlue"  @click="formDialog()">{{btnText}}</button>
    <div v-else-if="buttonColor=='slot'" @click="formDialog()" > 
      <slot name="btn"></slot>
    </div>
    <button v-else class="apply-btn" :class="{applyBtnred:btnRed}" :style="{color:mycolor,border:'2px solid '+mycolor}" @click="formDialog()">{{btnText}}</button>
     
    <div >
        <Modal 
          v-model="modelVisible"
          width="800"
          :mask-closable="false"
          @on-cancel="cancel"
          class="modal">
          <p slot="header" class="modalTitle">
            <span>{{btnText}}</span>
          </p>
          <applay-form :channel="channel" ref="form" @closeDialog="cancel"></applay-form>
          <div slot="footer" class="footer">
          </div>
        </Modal>
      </div>
  </div>
</template>
<script>
import { Modal,Button} from 'iview'
import ApplayForm from './ApplayForm.vue'
export default {
  name:'', 
  components: {
    Modal,
    Button,
    ApplayForm
  },
  props:{
    mycolor: {
      type:String,
      default:'#fff',
    },
    btnText:{
      type:String,
      default:'开通申请'
    },
    channel:{
      type:Boolean,
      default:false,
    },
    btnRed:{
      type:Boolean,
      default:false,
    },
    buttonColor: {
      type:String,
      default:'black',
    }
  },
  data() {
     return {
        modelVisible:false,
     };
  },
  computed: {},
  created() {
  },
  mounted() {

  },
  //方法集合
  methods: {
    // 开通申请
    formDialog(){
      this.modelVisible = true;
    },
    cancel(){
      this.modelVisible = false;
      this.$refs.form.$refs.formValidate.resetFields()
    }
  }
}
</script>
<style lang='scss'>
  //@import url(); 引入公共css类
  .modalTitle{
    color:#333;
    font-size:18px;
    text-align: center;
  }
  .modal .ivu-modal-footer{
     border-top:none;
  }
   .apply-btn{
    margin-top:35px;
    width:180px;
    $height:60px;
    line-height: $height;
    $background-color:#fff;
    background-color:rgba(255,255,255,0);
    border:2px solid $background-color;
    text-align: center;
    border-radius:10px;
    font-size:20px!important;
    color:$background-color;
    cursor: pointer;
  }
  .applyBtnred{
    background:#f07cbd;
    border-color:#f07cbd;
    font-size:24px;
    margin:30px auto 0;
    width:180px;
    display:block;
  }
  .white-button{
    margin-top:455px;
    margin-left: 160px;
    width:200px;
    background-color: #fff;
    text-align: center;
    border-radius:25px;
    font-size:16px;
    color: #13a6f6;
    cursor: pointer;
    border:none;
    line-height: 50px;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .blue-button{
    margin-top:300px;
    margin-left: 40px;
    width:200px;
    background-color: #40baff;
    text-align: center;
    border-radius:4px;
    font-size:16px;
    color: #fff;
    cursor: pointer;
    border:none;
    line-height: 50px;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .pur-button{
    margin-top:30px;
    width:200px;
    background-color: none;
    border:1px solid #5348F7;
    text-align: center;
    border-radius:4px;
    font-size:16px;
    color: #5348F7;
    cursor: pointer;
    border:none;
    line-height: 50px;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .pur{

  }
</style>