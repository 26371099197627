<template>
	<div id="app">
		<Nav :isShow="isShow"></Nav>
		<router-view />
		<Footer :isShow="isShow"  v-show="!$route.meta.footerShow"></Footer>
	</div>
</template>
<script>
import Nav from "@/global/Nav.vue";
import Footer from "@/global/Footer.vue";
import seoDictionary from "./utils/seoDictionary";
import { setTimeout } from "timers";
export default {
	name: "app",
	provide() {
		return {
			reload: this.reload,
		};
	},
	components: {
		Nav,
		Footer,
	},
	data() {
		return {
			isRouterAlive: true,
			isShow: true,
		};
	},
	metaInfo() {
		let routeName = this.$route.name;
		if (!!routeName) {
			let currentSeoInfo =
				seoDictionary.getDictionaryDataByRouteName(routeName);

        console.log('这里返回了路由名字',routeName)
        let hgt = []
        if(routeName == 'hgt'){
          hgt = [{
            content:'nofollow'
          }]
        }else {
            hgt = []
        }

			return {
				title: currentSeoInfo.title,
				meta: [
					{
						// set meta
						name: "keyWords",
						content: currentSeoInfo.keywords,
					},
					{
						// set meta
						name: "description",
						content: currentSeoInfo.description,
					},
          ...hgt
				],
			};
		} else {
			return {
				title:
					"电销系统-客户管理软件-电话营销系统-呼叫中心系统-电销软件代理-慧营销-浙江企蜂",
				meta: [
					{
						name: "keyWords",
						content:
							"电销系统,客户管理软件,电话营销系统,呼叫中心系统,电销软件代理,crm系统,电销系统,慧营销",
					},
					{
						name: "description",
						content:
							"浙江企蜂为企业提供优质电销系统、客户管理软件crm系统、电话营销系统、电销软件代理、智能语音系统外呼加盟、呼叫中心系统等。筛选意向客户的目的,慧营销将代替80%的重复劳动,全面提升销售效率。",
					},
				],
			};
		}
	},
	methods: {
  _isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  },
	reload() {
			this.isRouterAlive = false;
			this.$nextTick(function () {
				this.isRouterAlive = true;
			});
		},
		myBrowser() {
			var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
			var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
			var isIE =
				userAgent.indexOf("compatible") > -1 &&
				userAgent.indexOf("MSIE") > -1 &&
				!isOpera; //判断是否IE浏览器
			if (isIE) {
				var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
				reIE.test(userAgent);
				var fIEVersion = parseFloat(RegExp["$1"]);
				// 仅支持ie 10 以上版本
				if (fIEVersion < 10) {
					// alert("本网站暂不支持ie9及以下浏览器，请升级至ie10或使用其他浏览器打开")
					this.isShow = false;
					this.$router.push({ name: "browseDefault" });
				}
			}
		},
	},
	mounted() {
		this.myBrowser();
		let hostname = window.location.href;
		console.log(hostname, "hostname", !this._isMobile());
		if (this._isMobile()) {
			if (hostname) {
				if (hostname.indexOf("m_index") == -1) {
					window.location.href = "http://www.qftx.com/m_index.html"; //线上环境
				}
			}
		}
	},
};
</script>
<style src="./assets/css/base.css"></style>
<style lang="scss">
.apply-btn {
	margin-top: 35px;
	width: 180px;
	$height: 60px;
	line-height: $height;
	$background-color: #fff;
	background-color: rgba(255, 255, 255, 0);
	border: 2px solid $background-color;
	text-align: center;
	border-radius: 10px;
	font-size: 16px;
	color: $background-color;
	cursor: pointer;
}
.wrap {
	margin: 0 auto;
	width: 1200px;
}
.whyTitle {
	margin-top: 150px;
	margin-bottom: 65px;
	width: 100%;
	text-align: center;
	font-size: 48px;
	line-height: normal;
}
.pTtext {
	margin: 0 auto;
	font-size: 18px;
	width: 980px;
	text-align: center;
	line-height: 40px;
}
.applayBtnRed {
	display: block;
	margin: 50px auto;
	width: 214px;
	height: 60px;
	background: #f07cbd;
	font-size: 24px;
	text-align: center;
	line-height: 60px;
	border-radius: 10px;
	border: none;
	color: #fff;
	cursor: pointer;
}
.applayBtnBlue{
    display: block;
	width: 180px;
	height: 50px;
	background: #fff;
	font-size: 20px;
	text-align: center;
	line-height: 50px;
	border-radius: 5px;
	border:1px solid #3E7BF8;
	color: #3E7BF8;
	cursor: pointer;
}
.bigTitle {
    margin: 100px 0 40px;
    font-size: 30px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}
.btnArea{
    margin: 50px auto;
  display: flex;
  justify-content: center;
}
</style>
